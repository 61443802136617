<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="dossiers"
      class="elevation-0 myTable"
      disable-pagination
      hide-default-footer
      :options.sync="options"
      :server-items-length="total"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:no-data>
        Aucun dossier
      </template>
      <template v-slot:top>
        <v-toolbar flat extended extension-height="100">
          <v-toolbar-title id="toolbar-title"
            >Configuration des dossiers</v-toolbar-title
          >
          <text-input
            prepend-inner-icon="mdi-magnify"
            @keyup="handleSearch"
            class="ml-5 mt-5"
            id="search"
            v-model="filteredItem.name"
            placeholder="Nom du dossier"
          />
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="418px">
            <template v-slot:activator="{ on, attrs }">
              <Button
                v-bind="attrs"
                v-on="on"
                class="mb-2 mr-2"
                action_name="Ajouter"
                color="primary"
                text
                @clicked="openAddDialog"
              />
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">{{ formTitle }}</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form ref="form" style="width: 100%" v-model="isValid">
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="dossier"
                          v-model="editedItem.dossierIds"
                          :items="activeDossiers"
                          item-text="name"
                          item-value="id"
                          label="Dossiers*"
                          multiple
                          deletable-chips
                          small-chips
                          :rules="requiredRules"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          v-model="editedItem.configHours"
                          label="Nombre d'heures configurées*"
                          id="configHours"
                          :rules="requiredRules"
                          type="number"
                        />
                      </v-col>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="btn-actions">
                <Button
                  action_name="Continuer"
                  color="#86CC26"
                  @clicked="save(true)"
                  v-if="!showEditButton"
                  :loading="loading"
                />
                <Button
                  action_name="Ajouter"
                  color="#11887E"
                  text
                  :loading="loadingAdd"
                  @clicked="save(false)"
                  v-if="!showEditButton"
                />
                <Button
                  action_name="Modifier"
                  color="#11887E"
                  text
                  @clicked="update(false)"
                  v-if="showEditButton"
                  :loading="loadingEdit"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline"
                >Êtes vous sûr de supprimer cette ligne ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="secondary darken-1"
                  text
                  @click="deleteLine"
                  :loading="loadingDelete"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <div :key="item.id">
          {{ getDossiersName(item.dossiers) }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          @click="editItem(item)"
          class="ml-2 mt-1"
          style="min-width: 111px"
          >Modifier</v-btn
        >
        <v-btn
          @click="deleteItem(item)"
          class="ml-2 mt-1"
          style="min-width: 111px"
          >Supprimer</v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { Button, TextInput, AutoComplete } from "@/components/base";
import { getFoldersConfigurations } from "@/services/dossiers.api";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";
import {
  deleteConfigHours,
  getActiveDossiers,
  updateConfigHours,
} from "../../../services/dossiers.api";

export default {
  components: { Button, TextInput, AutoComplete },
  mixins: [infinityScrollMixin],
  data: () => ({
    total: 0,
    options: {},
    search: "",
    isValid: true,
    text: "",
    showEditButton: false,
    loading: false,
    loadingAdd: false,
    loadingEdit: false,
    items: [],
    dialog: false,
    headers: [
      {
        text: "Dossiers",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Heures configurées", value: "configHours" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dossiers: [],
    editedIndex: -1,
    editedItem: {},
    filteredItem: {},
    defaultItem: {},
    timeout: null,
    pageNumber: 1,
    last_page: 0,
    activeDossiers: [],
    dialogDelete: false,
    loadingDelete: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouvelle configuration"
        : "Modifier configuration";
    },
  },
  watch: {
    options: {
      handler() {
        this.dossiers = [];
        this.pageNumber = 1;
        getFoldersConfigurations(this.pageNumber, this.filteredItem).then(
          (response) => {
            this.dossiers = response.data.data;
            this.last_page = response.data.last_page;
            this.total = response.data.total;
          }
        );
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1;
        getFoldersConfigurations(this.pageNumber, this.filteredItem).then(
          (response) => {
            let responseArray = response.data.data;
            this.dossiers = [...this.dossiers, ...responseArray];
            this.total = response.data.total;
          }
        );
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    getConfigurations() {
      getFoldersConfigurations(this.pageNumber, this.filteredItem).then(
        (response) => {
          this.dossiers = response.data.data;
          this.last_page = response.data.last_page;
          this.total = response.data.total;
        }
      );
    },
    handleSearch() {
      if (this.filteredItem.name !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.pageNumber = 1;
          getFoldersConfigurations(this.pageNumber, this.filteredItem).then(
            (response) => {
              this.dossiers = response.data.data;
              this.last_page = response.data.last_page;
              this.total = response.data.total;
            }
          );
        }, 1000);
      } else {
        this.getConfigurations();
      }
    },
    openAddDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.dialog = true;
    },
    initialize() {
      getActiveDossiers().then((response) => {
        this.activeDossiers = response.data.dossiers;
      });
      this.getConfigurations();
    },
    editItem(item) {
      this.showEditButton = true;
      this.editedIndex = this.dossiers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.dossierIds = item.dossiers.map((dossier) => dossier.id);
      this.originalDossiers = item.dossiers.map((dossier) => dossier.id);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.showEditButton = false;
      });
    },
    update() {
      this.loadingEdit = true;
      const excludeDossiersIds = this.originalDossiers.filter(
        (id) => !this.editedItem.dossierIds.includes(id)
      );

      const payload = {
        configHours: this.editedItem.configHours,
        dossierIds: this.editedItem.dossierIds,
        excludeDossiersIds: excludeDossiersIds,
      };
      updateConfigHours(payload).then(() => {
        this.loadingEdit = false;
        this.showEditButton = false;
        this.getConfigurations();
        this.close();
        this.$swal.fire({ title: "Configuration modifiée avec succès" });
      });
    },
    save(dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      dialogStat ? (this.loading = true) : (this.loadingAdd = true);
      updateConfigHours(this.editedItem).then(() => {
        dialogStat ? (this.loading = false) : (this.loadingAdd = false);
        this.close();
        this.$refs.form.reset();
        this.dialog = dialogStat;
        this.$swal.fire({ title: "Configuration ajoutée avec succès" });
        this.getConfigurations();
      });
    },
    getDossiersName(dossiers) {
      const uniqueDepartments = [...new Set(dossiers.map((i) => i.name))];
      return uniqueDepartments.join(", ");
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.originalDossiers = item.dossiers.map((dossier) => dossier.id);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteLine() {
      this.loadingDelete = true;
      const excludeDossiersIds = this.originalDossiers;
      const payload = {
        excludeDossiersIds: excludeDossiersIds,
      };
      deleteConfigHours(payload).then(() => {
        this.loadingDelete = false;
        this.initialize();
        this.closeDelete();
        this.$swal.fire({ title: "Ligne supprimer avec succés", timer: 800 });
      });
    },
  },
};
</script>
<style scoped>
.myTable .datatable thead th.column.sortable i {
  display: none;
}

.v-icon.outlined {
  border: 1px solid currentColor;
  border-radius: 50%;
  padding: 3px;
}
</style>
